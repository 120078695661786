.container {
  padding: 20px;
}

.home-button {
  margin: 15px 0 30px 0;
}

.modules-buttons {
  margin-top: 30px;
}

.modules-buttons button {
  padding: 0;
  width: 50%;
  height: 90px;
  margin-bottom: 30px;
}

.soirvelance button {
  width: 40%;
  margin-right: 10px;
}

.text-center {
  text-align: center;
}

.module-accept {
  margin-top: 25px;
}

.module-accept button {
  padding: 20px;
}
